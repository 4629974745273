import React from "react"
// import { Link } from "gatsby"
import {
  injectIntl,
  Link,
  FormattedMessage,
  FormattedHTMLMessage,
} from "gatsby-plugin-intl"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import logo from "../images/INMAGIK_COLOR.svg"
import rawImage from '../images/rawgraphs.jpeg'
import wooPlanImage from '../images/wooplan.png'

import { IoIosAdd } from "react-icons/io"

const HomePage = () => {
  return (
    <Layout>
      <SEO title="INMAGIK Homepage" />
      <div className="jumbo-title container-fluid text-center bg-black text-white">
        <div className="font-100">
          <FormattedMessage id="home_welcome"></FormattedMessage>
        </div>
        <div className="display-2 font-200">INMAGIK</div>
        {/* <h1 className="display-4">INMAGIK</h1> */}
        <div className="text-center mt-2 mb-2 mb-md-5 mt-md-5 ">
          <img
            src={logo}
            width="80"
            height="80"
            className=""
            alt="inmagik logo"
          />
        </div>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <p className="lead font-200 px-2">
              <FormattedMessage id="home_title"></FormattedMessage>
            </p>
          </div>
        </div>
        <div className="text-center mt-5">
          <a
            className="font-100 pointer text-primary"
            onClick={() => {
              document.getElementById("learn-more-target").scrollIntoView({
                behavior: "smooth",
              })
            }}
          >
            <IoIosAdd size="3em"></IoIosAdd>
            <br />
            <FormattedMessage id="home_learn_more">Learn more</FormattedMessage>
          </a>
        </div>
      </div>

      <div className="main-content container p-4 py-5" id="learn-more-target">
        <div className="row my-3">
          <div className="col-md-8 offset-md-2 py-2 text-center">
            <h3 className="font-200 text-muted">
              <FormattedMessage id="home_what_we_do"></FormattedMessage>
            </h3>
            <p className="display-4 font-200">
              <span>IN</span><span className="text-primary">MAGIK</span><br></br>
              <FormattedMessage id="home_what_we_do_message"></FormattedMessage>
            </p>
            <p className="lead mt-5">
              <Link className="btn btn-primary" to="/services">
                <FormattedMessage id="home_our_services"></FormattedMessage>
              </Link>
            </p>
          </div>
        </div>

        <hr />

        <div className="row my-5">
          <div className="col-md-6 p-3 text-center">
            <h2 className="font-300 text-secondary">Web standards</h2>
            <p className="lead font-200">
              <FormattedHTMLMessage id="home_web_standards">
                We build multi platform software applications and digital
                products based on <b>modern web technologies.</b>
              </FormattedHTMLMessage>
            </p>
          </div>

          <div className="col-md-6 p-3 text-center">
            <h2 className="font-300 text-secondary">Open source based</h2>
            <p className="lead font-200">
              <FormattedHTMLMessage id="home_open_source">
                We leverage the power of mature open source frameworks like{" "}
                <b>Django</b> and <b>React</b> as solid building blocks for our
                application development.
              </FormattedHTMLMessage>
            </p>
          </div>
        </div>

        <hr />

        <div className="row my-3">
          <div className="col-md-6 offset-md-3 p-3 text-center">
            <a
              target="_blank"
              className="btn btn-primary mb-3"
              href="mailto:info@inmagik.com"
            >
              <FormattedMessage id="home_contact_us">
                CONTACT US
              </FormattedMessage>
            </a>
            <p className="lead">
              <FormattedMessage id="home_talk_with_us">
                Talk with us about your next digital project, website or
                application and request a free quotation.
              </FormattedMessage>
            </p>
          </div>
        </div>

        <hr />

        <div className="row mt-5 bg-light">
          <div className="col-md-12 text-center p-3">
            <h2 className="font-300 mb-0">News</h2>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-6 p-3 text-center">
            <div className="card">
              <div className="card-header">
                RAWGraphs v2 beta
              </div>
              <img className="img-cover-home" src={rawImage}></img>
              <div className="card-body font-300 card-body-home">
                  <FormattedHTMLMessage id="rawgraphs_home_card">
                    Talk with us about your next digital project, website or
                    application and request a free quotation.
                  </FormattedHTMLMessage>
                
              </div>
            </div>
          </div>

          <div className="col-md-6 p-3 text-center">
            <div className="card">
              <div className="card-header">
                WooPlan
              </div>
              <img className="img-cover-home" src={wooPlanImage}></img>
              <div className="card-body font-300 card-body-home">
                  <FormattedHTMLMessage id="wooplan_home_card">
                    Talk with us about your next digital project, website or
                    application and request a free quotation.
                  </FormattedHTMLMessage>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Link to="/">Go back to the homepage</Link> */}
    </Layout>
  )
}

export default injectIntl(HomePage)
